/**
 * React imports
 */
import React, { useState, useEffect } from "react";

/**
 * Components imports
 */
import PieComponent from "./closeResponses/PieComponent";
import BarComponent from "./scaleResponses/BarComponent";
import OpenComponent from "./openResponses/OpenComponent";
import SpinnerLoader from "../../../common/loaders/SpinnerLoader";
import { getScenarioStat } from "../../../../services/statServices";

/**
 * Others imports
 */
import { toast } from "react-toastify";

/**
 * Fetches data and processes it to generate components for display.
 *
 * @param {object} props - The properties object
 * @return {JSX.Element} The components to be displayed
*/
function FirstResume(props) {
  /*CONST */
  const id = !isNaN(+props?._id) && props?._id;

  /*HOOKS */
  const [loading, setLoading] = useState(true);
  const [components, setComponents] = useState([]);

  /*FUNCTIONS */


  /**
   * This function asynchronously retrieves data and handles any errors that may occur.
   *
   * @return {Promise} the response data if successful
  */
  async function getData() {
    try {
      const response = await getScenarioStat(id);
      if (response.header.code !== 200) {
        toast.error(response.header.message)
      } else {
        return response.response;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * This function is an asynchronous function that fetches data and processes it to generate an array of charts. 
   * It sets loading to true before fetching the data, and then sets it to false after processing the data. 
  */
  const dataSteps = async () => {
    setLoading(true)
    await getData().then((data) => {
      if (!data) {
        return null
      }
      const arrayOfCharts = [];
      Object.keys(data).map((key, index) => {
        const object = data[key];
        if (object?.type === "close") {
          const arrayOfAnswers = object?.answers?.map((val) => ({ id: val?.id, answer: val?.answer, tester_id: val?.clientTester?.id, name: `${val?.clientTester?.name.charAt(0).toUpperCase() + val?.clientTester?.name.slice(1)} ${val?.clientTester.lastname.charAt(0).toUpperCase()}` }));
          const arrayOfComment = object?.answers?.map((val) => val?.comment);
          const arrayOfNames = object?.answers?.map((val) => `${val?.clientTester?.name.charAt(0).toUpperCase() + val?.clientTester?.name.slice(1)} ${val?.clientTester.lastname.charAt(0).toUpperCase()}`);
          arrayOfCharts.push({
            component:
              <PieComponent key={index}
                {...{
                  graphData: object?.Graph,
                  question: object?.question,
                  index: index,
                  comments: arrayOfComment,
                  names: arrayOfNames,
                  totalResponse: object?.totalResponse,
                  answers: arrayOfAnswers,
                }}
              />
          });
        }
        if (object?.type === "open") {
          const arrayOfAnswers = object?.answers?.map((val) => ({
              id: val?.id, 
              answer: val?.answer, 
              tester_id: val?.clientTester?.id, 
              name: `${val?.clientTester?.name.charAt(0).toUpperCase() + val?.clientTester?.name.slice(1)} ${val?.clientTester.lastname.charAt(0).toUpperCase()}`, 
              comment: val?.comment
          }));
          arrayOfCharts.push({
            component:
              <OpenComponent
                key={index}
                {...{
                  answers: arrayOfAnswers,
                  question: object?.question,
                  etapeNumbre: object.step_number
                }}
              />
          });
        }
        if (object?.type === "scale") {
          const arrayOfAnswers = object?.answers?.map((val) => ({ id: val?.id, answer: val?.answer, tester_id: val?.clientTester?.id, name: `${val?.clientTester?.name.charAt(0).toUpperCase() + val?.clientTester?.name.slice(1)} ${val?.clientTester.lastname.charAt(0).toUpperCase()}` }));
          const arrayOfComment = object?.answers?.map((val) => val?.comment);
          const arrayOfNames = object?.answers?.map((val) => `${val?.clientTester?.name.charAt(0).toUpperCase() + val?.clientTester?.name.slice(1)} ${val?.clientTester.lastname.charAt(0).toUpperCase()}`);
          arrayOfCharts.push({
            component:
              <>
                <BarComponent key={index}
                  {...{
                    data: object.Graph,
                    question: object?.question,
                    index: index,
                    comments: arrayOfComment,
                    names: arrayOfNames,
                    infBorne: object.InfBorne,
                    supBorne: object.SupBorne,
                    answers: arrayOfAnswers,
                    totalResponse: object.totalResponse
                  }}
                />
              </>
          });
        }

        setComponents(arrayOfCharts);
      });
    });
    setLoading(false)
  }


  /* EFFECT */
  useEffect(() => {
    let mounted = true;
    const fetchDataSteps = async () => {
      if (mounted) {
        await dataSteps();
      }
    };

    fetchDataSteps();

    return () => {
      mounted = false;
    };
  }, [id]);


  /*RENDER */
  return (
    <>
      {
        loading ?
          <SpinnerLoader />
          :
          <>
            <div style={{ height: "350px" }} />
            {components?.map((item) => item?.component)}
          </>
      }
    </>
  );
}

export default FirstResume;
