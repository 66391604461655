/**
 * React imports
*/
import React from "react";

/**
 * reactstrap imports
*/
import { Input } from "reactstrap";

/**
 * Redux imports
 */
import  { useSelector } from 'react-redux'

/**
 * Render the left side of the header.
 *
 * @param {Array} scenarios - The list of scenarios.
 * @param {string} title - The title of the header.
 * @param {function} onChangeScenario - The function to handle scenario change.
 * @return {JSX.Element} The rendered header left side.
 */
const HeaderBarLeftSide = ({scenarios, onChangeScenario}) => {

    /* HOOKS */
    const { selectedScenario } = useSelector((state) => state.scenario);

    /*RENDER */
    return (
        <div>
        <Input type="select" name="select" className='btn_filter_select_scena-header' id="exampleSelect" onChange={onChangeScenario}>
            <option defaultValue="Sélectionner un scénario" defaultChecked selected disabled>Sélectionner un scénario</option>
            {
                scenarios.map((scenario, id) => {
                    if(selectedScenario.id !== null && selectedScenario.id === scenario.id){
                        return <option key={id} value={id} selected>{scenario.title}</option>
                    }else{
                        return <option key={id} value={id}>{scenario.title}</option>
                    }
                })
            }
        </Input>
    </div>
    )
}


export default HeaderBarLeftSide;