/**
 * React imports
*/
import React, { useState } from "react";

/**
 * reactstrap imports
*/
import { Label } from "reactstrap";

/**
 * components imports
*/
import SkeletonHeader from "../skeletonHeader.jsx";

/**
 * Images imports
 */
import Eye from '../../../../assets/eye.svg';
import HeaderScoreModal from "./HeaderScoreModal.jsx";


/**
 * Renders the right side of the header component.
 *
 * @param {Object} props - The props of the component.
 * @param {boolean} props.skeleton - Indicates if the component is in skeleton state.
 * @param {Array} props.header - The header data.
 * @param {number} props.nbrTester - The number of testers.
 *
 * @return {JSX.Element} The JSX element representing the right side of the header component.
 */
const HeaderBarRightSide = ({skeleton, header, nbrTester, analyseObj}) => {

    /* HOOKS */
    const [open, setOpen] = useState(false);

    /* FUNCTIONS */

    /**
     * Handles the opening of the modal.
     */
    const handleOpenModal = () => {
        setOpen((prevState) => !prevState);
    }


    /* RENDER */
    return(
        <div className='anaylyse_header-etapes'>
            <div className='anaylyse-etape'>
                <Label className="header_label">Etapes</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.steps ? <span className='header_span'>{header?.steps} </span> : ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Testeurs</Label>
                {
                    skeleton ? 
                        <SkeletonHeader /> 
                    : 
                        header?.testers ? 
                            <>
                                {
                                    nbrTester === header?.testers ?
                                        <span className='header_span completTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                    :
                                        <span className='header_span encoursTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                }
                            </>
                        :
                            ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Panel</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.type ? <span className='header_span'>{header?.type === 'client' ? 'Client' : header?.type}</span> : ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label" data-tip='Le score peut aller de -1 à 1'>Score</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.score ? 
                            <span className='header_span' style={{ color: header?.score >= 0 ? 'green' : 'red' }}>{header?.score}</span>
                        : 
                            ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Durée</Label>
                {
                    skeleton ? 
                        <SkeletonHeader />  
                    : 
                        header?.duration ? 
                            <span className='header_span'>
                                {`${Math.floor(header?.duration / 60) > 0 ? Math.floor(header?.duration / 60) + 'min :' : ''} ${Math.floor(header?.duration) % 60} sec`}
                            </span> 
                        : 
                            ""
                }
            </div>
            <div className='anaylyse-etape-img'>
                <Label className="header_label">Résumé</Label>
                {
                    skeleton ?  <SkeletonHeader /> : header?.score ?
                            <img src={Eye} alt="2M-advisory" style={{ width: '35px', cursor: 'pointer' }} onClick={handleOpenModal} />
                            : ""
                }
            </div>
            <HeaderScoreModal open={open} handleOpenModal={handleOpenModal} header={header} analyseObj={analyseObj} />
        </div>
    );
}

export default HeaderBarRightSide;