/**
 * React imports
*/
import React from "react";

/**
 * components imports
*/
import HeaderBarRightSide from "./HeaderBarRightSide";
import HeaderBarLeftSide from "./HeaderBarLeftSide";


/**
 * Renders the AnalyseHeaderBar component.
 *
 * @param {Object} skeleton - The skeleton object.
 * @param {Object} header - The header object.
 * @param {number} nbrTester - The number of testers.
 * @param {Array} scenarios - The list of scenarios.
 * @param {string} title - The title of the component.
 * @param {function} onChangeScenario - The function to handle scenario change.
 * @return {JSX.Element} - The rendered AnalyseHeaderBar component.
 */
const AnalyseHeaderBar = ({skeleton, header, nbrTester, scenarios, title, onChangeScenario, dataAv,analyseObj}) => {

    return(
        <>
            <div className="sub-header-title-section">
                <span className="sub-header-title">Sélectionner un scénario:</span>
            </div>
            <HeaderBarLeftSide scenarios={scenarios} title={title} onChangeScenario={onChangeScenario} />
            <HeaderBarRightSide skeleton={skeleton} modalData={dataAv} header={header} nbrTester={nbrTester} analyseObj={analyseObj}/>
        </>
    );
}


export default AnalyseHeaderBar;