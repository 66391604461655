/**
 * React imports
 */
import React, { useState, useEffect, useCallback } from 'react';

/**
 * Redux imports
 */
import { connect } from 'react-redux';

/**
 * Services imports
 */
import { getAllDataScenarios } from "../../services/scenarioServices";
import { getScenarioDetails, } from "../../services/statServices";
import { getPanelStats } from '../../services/analyzeServices'

/**
 * Components imports
*/
import Favorite from '../common/favorite';
import SpinnerLoader from "../common/loaders/SpinnerLoader";

/**
 * Bootstrap imports
 */
import { toast } from 'react-toastify';

/**
 * Styles
 */
import './statistics.css'
import ChartStat from './ChartStat';
import Header from '../landingPages/client/rawComponents/Header';

/**
 * Renders the StatPanel component.
 *
 * @param {Object} props - The props object containing the component's properties.
 * @return {JSX.Element} The rendered StatPanel component.
*/
const StatPanel = (props) => {

  /*HOOKS */
  const [scenarios, setScenarios] = useState([]);
  const [headerPayload, setHeaderPayload] = useState([]);
  const [_id, setId] = useState(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);


  /* FUNCTIONS */
  const renderData = (data) => {
    let data1 = []
    data?.map(obj =>
      data1 = [...data1, obj.value]
    )
    return data1
  }
  const renderLabel = (label) => {
    let label1 = []
    label?.map(obj =>
      label1 = [...label1, obj.label]
    )
    return label1
  }

  /** VARIABLES */
  const chartColors = {
    datasets: [
      {
        label: '# of Votes',
        backgroundColor: [
          '#17A25C',
          '#9FD9BC',
          '#117543',
          "#91BFA8",
          '#D4D2D2',
          '#ZEFZEF',
          "#0C5430",
          "#404040",
        ],
        borderColor: [
          '#17A25C',
          '#9FD9BC',
          '#117543',
          "#91BFA8",
          '#D4D2D2',
          '#ZEFZEF',
          "#0C5430",
          "#404040",
        ],
        borderWidth: 1,
        hoverOffset: 7,
      },
    ],
  };

  /**
   * Retrieves scenarios data from the API and updates the state with the response.
   *
   * @return {void} This function does not return a value.
  */
  const getScenarios = () => {

    try {
      getAllDataScenarios().then(response => {
        if (response?.header.code !== 200) {
          toast.error(response?.header.message)
        } else {
          setScenarios(response?.response)
        }
      });
    } catch (err) {
      console.log(err)
    }
  };


  /**
   * Retrieves the scenario details for a given ID and sets the header payload if the response code is 200.
   *
   * @param {type} id - the ID of the scenario
   * @return {type} undefined
  */
  const getScenario = (id) => {
    if (id) {
      try {
        getScenarioDetails(id)
          .then((response) => {
            if (response?.header.code !== 200) {
              toast.error(response?.header.message)
            } else {
              setHeaderPayload(response?.response);
            }
          });
      } catch (err) {
        toast.error('Error while fetching scenario details')
        console.error('Error',err)
      }
    }
  };


  /**
    * Handles the change event of the scenario.
    *
    * @param {Event} e - The change event object.
    * @return {void} - This function does not return a value.
  */
  const onChangeScenario = (e) => {
    if (isNaN(e)) {
      setId(e.target.value);
    } else {
      setId(+e);
    }
    setIsEmpty(false)
  }

  /* HOOKS */
  useEffect(() => {
    if (props.scenario.selectedScenario.id !== null && props.scenario.selectedScenario.id !== undefined) {
      onChangeScenario(props.scenario.selectedScenario.id);
    }
    try {
      getScenario(_id);
    } catch {
      toast.error('Une erreur est survenue')
    }
    return () => {
      setLoading(false)
      setHeaderPayload([]);
      setStats({});
    };
  }, [_id]);


  useEffect(() => {
    getScenarios();
  }, []);


  const getPanelStatData = useCallback(async () =>{
    if(_id){
      setLoading(true)
        try{
          const response = await getPanelStats(_id, 0);
            if(response.header?.code !== 200){
              toast.error(response?.header?.message)
            }else{
              let orderStat = []
              if(response?.response){
                orderStat = {
                  ageRange: response?.response?.ageRange,
                  os: response?.response?.os,
                  map: response?.response?.map,
                  gender: response?.response?.gender,
                  csp: response?.response?.csp
                }
              }
              setStats(orderStat);
            }

        }catch (err) {
          toast.error('Une erreur est survenue')
          console.error("Une erreur est survenue",err);
        }
      }
    setLoading(false)
  },[_id])

  useEffect(() => {
    getPanelStatData()
  }, [getPanelStatData]);


  //RENDRE
  return (
    <div className="statPanel" >
      <div className='col-top-component-container'>
        <Favorite />
        <Header
          header={headerPayload}
          nbrTester={headerPayload?.testers}
          scenarios={scenarios}
          onChangeScenario={onChangeScenario}
          selectedScenario={props.scenario.selectedScenario}
        />
        <br />
      </div>
      <div style={{ height: "325px" }} />
      {
        isEmpty === false ?
          <>
            {
              loading ? 
                <SpinnerLoader />
              :
                <div className='dashboard_panel_container'>
                  {
                    Object.entries(stats).map(([label, data], index) => (
                      data.length > 0 &&
                        <ChartStat 
                          key={index}
                          label={label}
                          labels={renderLabel(data)}
                          data={renderData(data)}
                          backgroundColor={chartColors.datasets[0].backgroundColor}
                          borderColor={chartColors.datasets[0].borderColor} 
                        />
                    ))
                  }
                </div> 
            }
          </>  
          :
            <div style={{ margin: '1rem auto' }}>
              Veuillez sélectionner un scénario
            </div>
        }
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  scenario: state.scenario,
});

export default connect(
  mapStateToProps,
  {}
)(StatPanel);