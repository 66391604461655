/**
 * React imports
 */
import React from "react";

/**
 * ChartJS imports
 */
import LineChart from '../../../common/charts/lineChart2';

/**
 * Images imports
 */
import StepChips from "../StepChips";

/**
 * Renders the chart section for analyzing data.
 *
 * @param {Object} btn2 - The value of filter button.
 * @param {Function} filter2 - The filter2 function.
 * @param {Function} filter - The filter function.
 * @param {Object} dataAv - The dataAv object.
 * @param {Object} dataMoyChart - The dataMoyChart object.
 * @param {Object} dataEcartChart - The dataEcartChart object.
 * @param {Function} stepView - The stepView function.
 * @param {Function} onChangeStep - The onChangeStep function.
 * @param {Object} analyseObj - The analyseObj object.
 * @return {JSX.Element} The rendered chart section.
 */
const AnalyseChartSection = ({ btn2, filter, dataMoyChart, dataEcartChart, stepView, onChangeStep, analyseObj }) => {

    const dataAndLabels = [];

    analyseObj.forEach(obj => {
        dataAndLabels.push({ average: parseFloat(obj.average), labels: obj.labels });
    });

    dataAndLabels.sort((a, b) => a.average - b.average);

    const data = dataAndLabels.map(item => item.average);
    const labels = dataAndLabels.map(item => item.labels);

    const handleChangeEtape = (step) => {
        if (step !== undefined && step !== null) {
            if(isNaN(step)){
                step = step.substring(1);
                step = parseInt(step);
            }else{
                step = step + 1
            }
            onChangeStep(step);
            stepView();
        }
    }

    const options = {
        responsive: true,
        type: 'line',
        plugins: {
            datalabels: {
                display: true,
                align: 'end',
                anchor: 'end',
                color: function (context) {
                    var index = context.dataIndex;
                    var value = +context.dataset.data[index];
                    return value < 0 ? 'red' :  // draw negative values in red
                        value === 0 ? 'black' :  // else, default color
                            'green';            // else, draw positive values in green
                },
                font: {
                    weight: 'bold',
                    size: 12,
                },
            },
            legend: {
                display: true,
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
            },
        },
        scales: {
            x: {
                type: 'category',
                grid: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)", // Couleur de la ligne de grille pour l'axe des abscisses
                    lineWidth: 1, // Épaisseur de la ligne de grille pour l'axe des abscisses
                },
            },
            y: {
                ticks: {
                    color: function (context) {
                        return context.tick?.value === 0 ? 'black' : context.tick?.value > 0 ? "green" : 'red';
                    },
                },
                grid: {
                    display: true,
                    color: (context) => {
                        if (context.tick?.value === 0) {
                            return "black"; // La ligne du zéro sera noire
                        } else {
                            return "rgba(0, 0, 0, 0.1)"; // Les autres lignes seront grises
                        }
                    },// Couleur de la ligne de grille pour l'axe des abscisses
                    //  les lignes de la grille sont en pointillé
                    borderDash: [4, 2],
                },
                suggestedMin: -0.5,
                suggestedMax: 0.5,
                type: 'linear',
            }
        },
        borderColor: 'grey',
        backgroundColor: 'grey',
        cubicInterpolationMode: 'monotone',
        pointRadius: 2.8,
        pointHoverRadius: 6,
        fill: false,
        onClick: (event, elements) => elements && elements[0]?.datasetIndex === 0 && handleChangeEtape(elements[0]?.index),
    };
    

    /* RENDER */
    return(
        <>
            <div className='filter-section'>
                <span className="ml-1 question-text-chart"> Veuillez selectionner un point du graphique pour voir le détail des étapes </span>
            </div>
            
            <div className='chart_container_analyse'>
                <div>
                    <span className='journey_map' onClick={() => filter('map')}>Journey Map</span>
                </div>
                <div className='chart-section'>
                    <div className='chart_style' data-tip='Moyenne des ressentis par étape'>
                        <LineChart
                            data={btn2 === 'moyenne' ? dataMoyChart : dataEcartChart}
                            options={options}
                        />
                    </div>
                </div>
                <div className='chart_footer'>
                    <div className='chart_footer_TOP'>
                        <div className='top_color'>TOP</div>
                        <div className='result_container'>
                            <StepChips labels={labels[labels.length - 1]} data={data && data[data.length - 1]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop' />
                            {
                                data && data?.length >= 6 &&
                                <>
                                    <StepChips labels={labels[labels.length - 2]} data={data && data[data.length - 2]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop' />
                                    <StepChips labels={labels[labels.length - 3]} data={data && data[data.length - 3]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop'/>
                                </>
                            }
                        </div>
                    </div>
                    <div className='chart_footer_FLOP'>
                        <div className='flop_color'>FLOP</div>
                        <div className='result_container'>
                            <StepChips labels={labels[0]} data={data[0]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop'/>
                            {
                                data && data?.length >= 6 &&
                                <>
                                    <StepChips labels={labels[1]} data={data[1]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop'/>
                                    <StepChips labels={labels[2]} data={data[2]} handleChangeEtape={handleChangeEtape} classNameContainer='etape_result_top-flop'/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyseChartSection;