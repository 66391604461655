import React, {useState, useEffect} from "react";
import {
  ModalHeader, Modal, ModalBody
} from 'reactstrap';
import '../register/client/register.css'

const Modals = (props) => {
  const { show, toggleShow, header, modalSize, style, backdrop, fullscreen, notWithCloseButton = false } = props
  const [backdropValue, setBackdropValue] = useState(backdrop);


  useEffect(() => {
    if (backdropValue !== 'static') {
      setBackdropValue(true);
    }
  }, []);

  return (
    <Modal
      style={{ minWidth: style ? style : "" }}
      isOpen={show}
      toggle={() => toggleShow()}
      aria-labelledby={modalSize ? `contained-modal-title-vcenter ${modalSize}` : "contained-modal-title-vcenter"}
      backdrop={backdropValue}
      centered
      fullscreen={fullscreen ? fullscreen : "false"}
    >
      {
        header && notWithCloseButton ? <ModalHeader>{header}</ModalHeader>
                                     : <ModalHeader toggle={() => toggleShow()}>{header}</ModalHeader>
      }
      <ModalBody>
        {props.children}
      </ModalBody>
    </Modal>
  )
}
export default Modals;