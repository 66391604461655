import React, { useState } from "react";
import { Input, Label } from "reactstrap"
import { downloadExcel } from "../../../../services/statServices"
import { toast } from "react-toastify";
import Download from "../../../../assets/dwnld.png"


const Header = ({ header, nbrTester, scenarios, onChangeScenario, selectedScenario, excel = false, arrayOftesters }) => {

    const [isDonwloading, setIsDonwloading] = useState(false);

    /**
     * Downloads all data as an Excel file.
    */
    const handleDownloadAllData = async () => {
        let url;
        try {
            setIsDonwloading(true);
            const res = await downloadExcel(header.id, arrayOftesters)
            if(res?.header?.code === 400){
                toast.error(res?.header?.message)
                return
            }

            url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `allData_${header?.title ? header?.title : "data"}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Fichier télécharge avec succès");
        } catch (error) {
            console.log("ERROR",error);
        }
        finally {
            setIsDonwloading(false);
            URL.revokeObjectURL(url);
        }
    }

    /**RENDER */
    return (
        <div className='header-analyse'>
            <div className="sub-header-title-section">
                <span className="sub-header-title">Sélectionner un scénario:</span>
            </div>
            <div> 
                <Input type="select" name="select" className='btn_filter_select_scena-header' id="exampleSelect" onChange={onChangeScenario}>
                    <option defaultValue="Sélectionner un scénario" defaultChecked selected disabled>Sélectionner un scénario</option>
                    {
                        scenarios.map((scenario, id) => {
                            if (selectedScenario.id !== null && selectedScenario.id === scenario.id) {
                                return <option key={id} value={scenario.id} selected>{scenario.title}</option>
                            } else {
                                return <option key={id} value={scenario.id}>{scenario.title}</option>
                            }
                        })
                    }
                </Input>
            </div>
            <div className='anaylyse_header-etapes'>
                <div className='anaylyse-etape'>
                    <Label className="header_label">Etapes</Label>
                    {
                        header?.steps ?(
                            <span className='header_span'>{header?.steps} </span> 
                        ):(
                            ""
                        )
                    }
                </div>
                <div className='anaylyse-etape'>
                    <Label className="header_label">Testeurs</Label>
                    {
                        header?.testers ?(
                            <>
                                {
                                    nbrTester === header?.testersDone ?
                                        <span className='header_span completTest'>
                                            {`${header?.testersDone}/${nbrTester}`}
                                        </span>
                                        :
                                        <span className='header_span encoursTest'>
                                            {`${header?.testersDone}/${nbrTester}`}
                                        </span>
                                }
                            </>
                        ):(
                            ""
                        )
                    }
                </div>
                <div className='anaylyse-etape'>
                    <Label className="header_label">Panel</Label>
                    {
                        header?.type ? (
                            <span className='header_span'>{header?.type === 'client' ? 'Client' : header?.type}</span> 
                        )
                        :
                        (
                            ""
                        )
                    }
                </div>
                <div className='anaylyse-etape'>
                    <Label className="header_label" data-tip='Le score peut aller de -1 à 1'>Score</Label>
                    {
                        header?.score ?(
                            <span className='header_span' style={{ color: header?.score >= 0 ? 'green' : 'red' }}>{header?.score}</span>
                        ):(
                            ""
                        )
                    }
                </div>
                <div className='anaylyse-etape'>
                    <Label className="header_label">Durée</Label>
                    {
                        header?.duration ? (
                            <span className='header_span'>
                                {`${Math.floor(header?.duration / 60) > 0 ? Math.floor(header?.duration / 60) + 'min :' : ''} ${Math.floor(header?.duration) % 60}sec`}
                            </span>
                        ):(
                            ""
                        )
                    }
                </div>
                {
                    excel && (
                        <div className='anaylyse-etape'>
                            <Label className="header_label">Télécharger</Label>
                            {header?.testers ?
                                <span className='header_span'>
                                    {
                                        isDonwloading ? 
                                            <i className="fa fa-spinner fa-pulse" aria-hidden="true"></i>
                                        : 
                                            <img src={Download} alt="" style={{ width: "1.5rem", cursor: "pointer" }} onClick={handleDownloadAllData} />
                                    }
                                </span>
                            : 
                                ""
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}


export default Header;