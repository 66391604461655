import React from 'react'
import { Button } from "reactstrap"

/**
 * Render a button with optional loading state and name.
 *
 * @param {boolean} loading - flag to indicate loading state
 * @param {string} name - the name to display on the button
 * @return {JSX.Element} the rendered button component
*/
const LoaderButton = ({loading, name, ...props}) => {
  return (
    <Button {...props} disabled={loading}>
        {loading && <i className="fa fa-refresh fa-spin mr-2" />}
        {name}
    </Button>
  )
}

export default LoaderButton;