/**
 * React imports
 */
import React from "react";

/**
 * components imports
 */
import AnalyseHeaderBar from "./AnalyseHeaderBar";


/**
 * Renders the AnalyseHeader component.
 *
 * @param {Object} skeleton - The skeleton object.
 * @param {Object} header - The header object.
 * @param {number} nbrTester - The number of testers.
 * @param {Array} scenarios - The scenarios array.
 * @param {string} title - The title string.
 * @param {function} onChangeScenario - The callback function for scenario change.
 * @return {ReactNode} The rendered AnalyseHeader component.
 */
const AnalyseHeader = ({skeleton, header, nbrTester, scenarios, title, onChangeScenario, dataAv, analyseObj})=> {
    return(
        <>
            <div className='analyse-title-section'>
                <span className="analyse-title">Analyse sémantique</span>
            </div>
            <div className='header-analyse'>
                <AnalyseHeaderBar
                    skeleton={skeleton} 
                    header={header} 
                    nbrTester={nbrTester} 
                    scenarios={scenarios} 
                    title={title} 
                    onChangeScenario={onChangeScenario} 
                    dataAv={dataAv}
                    analyseObj={analyseObj}
                />
            </div>
        </>
    )

}


export default AnalyseHeader;