import React from 'react';
import { connect } from 'react-redux';
import { Row, Label, Button, Input } from "reactstrap"
import Filter from '../../../assets/filter.svg'
import Eye from '../../../assets/eye.svg'
// import Excel from '../../../assets/excel.svg'
// import Heart from '../../../assets/heart.svg'
// import Compare from '../../../assets/compare.svg'
// import Info from '../../../assets/info.svg'
// import Linechart from "../../common/charts/lineChart2"
import Bar from "../../common/charts/bar"
import axios from "axios";
import { getAuthorization } from "../../../services/userServices";
import { apiUrl } from "../../../config.json";
// import video from "../../../assets/presentation.mp4"
// import Table from "../../common/table"
// import { Player } from 'video-react';
import { getScenarioById, getClientScenarios } from '../../../services/scenarioServices'
import {
  getScenarioAnalysesR, getStepAnalysesR,// getTestById 
} from '../../../services/analyzeServices'
import CommentaireForm from '../commentaire'
import Modals from '../../common/modal'
// import ReactTooltip from 'react-tooltip';
import { Skeleton, Space } from 'antd';
import { BarChartOutlined } from '@material-ui/icons';
import "./facialRecognition.css"
const apiEndpoint = apiUrl

class FacialRecognition extends React.Component {
  state = {
    btn: 'joie',
    scenarios: [],
    scenario: {},
    step: {},
    header: [],
    skeleton: false,
    nbrTester: 0,

  }

  componentDidMount() {
    let nbrtester = 0;
    this.props.scenario?.scenarios[0] && axios.get(apiEndpoint + `/api/scenario/${this.props.scenario?.scenarios[0]?.id}/details`, {
      headers: getAuthorization(),
    })
      .then((response) => {
        //this.setState({ header: response.data })
        nbrtester = response.data[0].testers
      })
      .catch((err) => {
        console.log(err);
      });
    getClientScenarios().then(res => {
      this.setState({
        scenarios: res,
      })
    })
    getScenarioAnalysesR(this.props.scenario.scenarios.length - 1).then(res => {
      this.setState({ scenariosAnalyze: res })
    })
    getStepAnalysesR(this.props.scenario.scenarios.length - 1).then(res => {
      this.setState({ stepAnalyze: res, nbrTester: res.answers.length })
    })
    getScenarioById(this.props.scenario.scenarios.length - 1).then(res => {
      this.setState({ scenario: res, step: res.steps[res.steps.length - 1] })
    })

  }
  toggle = () => {
    this.setState({ show: !this.state.show })

  }
  renderModalCommentaire() {
    const { clientTesters, userLabel, loading } = this.state
    return (
      <Modals
        // modalSize="modal-lg"
        show={this.state.show}
        toggleShow={this.toggle}
        header='Ajouter un commentaire'
      >
        <CommentaireForm
          confirmText='Ajouter commentaire'
          handleSubmit={this.onSubmit}
          onchange={this.onChange}
          currentUser={clientTesters}
          userText={userLabel}
          loading={loading}
          error={this.state.error}
        />

      </Modals>
    )
  }

  filter = (e) => {
    this.setState({ ...this.state, btn: e })
  }
  onChange = (e) => {
    this.setState({
      step: this.state.scenario.steps[e.target.value]
    }, () => {
      getStepAnalysesR(this.state.step?.id).then(res => {
        this.setState({ stepAnalyze: res })
      })
    })
  }
  onChangeScenario = (e) => {
    this.setState({ loading: true, skeleton: true })
    axios.get(apiEndpoint + `/api/scenario/${this.state.scenarios[e.target.value].id}/details`, {
      headers: getAuthorization(),
    })
      .then((response) => {
        this.setState({ header: response.data, loading: false, skeleton: false })
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({
      scenario: this.state.scenarios[e.target.value]
    }, () => {
      getScenarioAnalysesR(this.state.scenario.id).then(res => {
        this.setState({ scenariosAnalyze: res })
      })
    })
  }
  render() {

    const { btn, //btn2, scenario, scenarios, 
      skeleton, header, nbrTester } = this.state

    const rand = () => Math.round(Math.random() * 20 - 10);

    const data = {
      labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
      datasets: [
        {
          type: 'line',
          label: 'moyenne',
          backgroundColor: '#00a359',
          borderColor: '#00a359',
          borderWidth: 2,
          fill: false,
          data: [rand(), rand(), rand(), rand(), rand(), rand()],
        },
        {
          type: 'scatter',
          label: 'testeur 1',
          backgroundColor: '#00a359',
          borderColor: '#00a359',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'testeur 2',
          backgroundColor: '#00a359',
          borderColor: '#00a359',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'testeur 3',
          backgroundColor: '#00a359',
          borderColor: '#00a359',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },

      ],
    };
    const data1 = {
      labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
      datasets: [
        {
          type: 'line',
          label: 'Dataset 1',
          backgroundColor: '#024089',
          borderColor: '#024089',
          borderWidth: 2,
          fill: false,
          data: [rand(), rand(), rand(), rand(), rand(), rand()],
        },
        {
          type: 'scatter',
          label: 'Dataset 2',
          backgroundColor: '#024089',
          borderColor: '#024089',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 3',
          backgroundColor: '#024089',
          borderColor: '#024089',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 4',
          backgroundColor: '#024089',
          borderColor: '#024089',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },

      ],
    };
    const data2 = {
      labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
      datasets: [
        {
          type: 'line',
          label: 'Dataset 1',
          backgroundColor: '#F68E12',
          borderColor: '#F68E12',
          borderWidth: 2,
          fill: false,
          data: [rand(), rand(), rand(), rand(), rand(), rand()],
        },
        {
          type: 'scatter',
          label: 'Dataset 2',
          backgroundColor: '#F68E12',
          borderColor: '#F68E12',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 3',
          backgroundColor: '#F68E12',
          borderColor: '#F68E12',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 4',
          backgroundColor: '#F68E12',
          borderColor: '#F68E12',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },

      ],
    };
    const data3 = {
      labels: ['etape 1', 'etape 2', 'etape 3', 'etape 4', 'etape 5', 'etape 6'],
      datasets: [
        {
          type: 'line',
          label: 'Dataset 1',
          backgroundColor: '#FB4141',
          borderColor: '#FB4141',
          borderWidth: 2,
          fill: false,
          data: [rand(), rand(), rand(), rand(), rand(), rand()],
        },
        {
          type: 'scatter',
          label: 'Dataset 2',
          backgroundColor: '#FB4141',
          borderColor: '#FB4141',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 3',
          backgroundColor: '#FB4141',
          borderColor: '#FB4141',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },
        {
          type: 'scatter',
          label: 'Dataset 4',
          backgroundColor: '#FB4141',
          borderColor: '#FB4141',
          data: [rand(), rand(), rand(), rand(), rand(), rand(), rand()],
          borderWidth: 2,
        },

      ],
    };
    const options2 = {
      responsive: true,
      type: 'line',
      plugins: {
        datalabels: {
          display: true,
          align: 'end',
          anchor: 'end',
          color: function (context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            return value < 0 ? 'red' :  // draw negative values in red
              value === 0 ? 'black' :  // else, default color
                'green';            // else, draw positive values in green
          },
          font: {
            weight: 'bold',
            size: 12,
          },
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
        },
      },
      scales: {
        x: {
          type: 'category',
          grid: {
            display: true,
            color: "rgba(0, 0, 0, 0.1)", // Couleur de la ligne de grille pour l'axe des abscisses
            lineWidth: 1, // Épaisseur de la ligne de grille pour l'axe des abscisses
          },
        },
        y: {
          ticks: {
            color: function (context) {
              return context.tick?.value === 0 ? 'black' : context.tick?.value > 0 ? "green" : 'red';
            },
          },
          grid: {
            display: true,
            color: (context) => {
              if (context.tick?.value === 0) {
                return "black"; // La ligne du zéro sera noire
              } else {
                return "rgba(0, 0, 0, 0.1)"; // Les autres lignes seront grises
              }
            },// Couleur de la ligne de grille pour l'axe des abscisses
            //  les lignes de la grille sont en pointillé
            borderDash: [2, 2],
          },
          suggestedMin: -1,
          suggestedMax: 1,
          type: 'linear',
        }
      },
      // backgroundColor:
      //     dataAvSorted?.map((value, idx) => {
      //         return value < 0 ? 'red' : '#00a359'
      //     } 
      //     ),
      // pointBorderColor:
      //     dataAvSorted?.map((value, idx) => {
      //         return value < 0 ? 'red' : '#00a359'
      //     } 
      //     ),
      borderColor: 'grey',
      cubicInterpolationMode: 'monotone',
      pointRadius: 2.8,
      pointHoverRadius: 7,
      fill: false,
      onClick: (event, elements) => {
        if (elements.length > 0) {
          let etape = elements[0].index + 1
          this.setState({ cliqueEtape: etape }, () => { this.onChange(etape); });
          this.stepView();
        }
      }
    };


    const dataOptions = {
      labels: ['testeur 1', 'testeur 2', 'testeur 3', 'testeur 4', 'testeur 5', 'testeur 6'],
      datasets: [
        {
          label: "indice d'emotion",
          data: [4, 1, 3, 5, 2, 3],
          backgroundColor: "#17A25C ",
          borderColor: "#00a359",
        },
      ],
    };

    const options = {
      indexAxis: "x",
      ticks: {
        beginAtZero: true, // minimum value will be 0.
        // <=> //
        min: 0,
        max: 5,
        stepSize: 1, // 1 - 2 - 3 ...
        major: {
          //           enabled: true
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
          },
        ],
      },
    };
    return (<>
      <div className='analyze_form'>
        <div className='analyse-title-section'>
          <span className="analyse-title">Reconnaissance Faciale</span>
        </div>
        <div className='header-analyse'>
          <div className="sub-header-title-section">
            <span className="sub-header-title">Sélectionner un scénario:</span>
          </div>
          <div>
            <Input type="select" name="select" className='btn_filter_select_scena-header' id="exampleSelect" onChange={this.onChangeScenario}>
              <option selected disabled>Sélectionner un scénario</option>
              {
                this.state.scenarios?.map((scena, idx) => {
                  return <option key={idx} value={idx}>{scena.title}</option>
                })
              }
            </Input>
          </div>
          <div className='anaylyse_header-etapes'>
            <div className='anaylyse-etape'>
              <Label className="header_label">Etapes</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header[0]?.steps ?
                    <span className='header_span'>{header[0]?.steps} </span>
                    :
                    ""
              }
            </div>
            <div className='anaylyse-etape'>
              <Label className="header_label">Testeurs</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header[0]?.testers ?
                    <span className='header_span'>{nbrTester}</span>
                    :
                    ""
              }
            </div>
            <div className='anaylyse-etape'>
              <Label className="header_label">Panel</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header[0]?.type ?
                    <span className='header_span'>{header[0]?.type === 'client' ? 'Client' : header[0]?.type}</span>
                    :
                    ""
              }
            </div>
            <div className='anaylyse-etape'>
              <Label className="header_label" data-tip='Le score peut aller de -1 à 1'>Score</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header[0]?.score ?
                    <span className='header_span' style={{ color: header[0]?.score >= 0 ? 'green' : 'red' }}>{parseFloat(header[0]?.score).toFixed(2)}</span>
                    :
                    ""
              }

            </div>
            <div className='anaylyse-etape'>
              <Label className="header_label">Durée (sec)</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header?.duration?.toFixed(2) ?
                    <span className='header_span'>{header?.duration?.toFixed(2)}</span>
                    :
                    ""
              }
            </div>
            <div className='anaylyse-etape-img'>
              <Label className="header_label">Résumé</Label>
              {
                skeleton ?
                  <Space size={12} direction='horizontal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton
                      active
                      title={{ width: 50, height: 10 }}
                      paragraph={{ rows: 0 }}
                    />
                  </Space>
                  :
                  header[0]?.score ?
                    <img src={Eye} alt="2M-advisory" style={{ width: '35px', cursor: 'pointer' }} onClick={() => this.toggle2()} />
                    :
                    ""
              }
            </div>
          </div>
        </div>
        {
          skeleton ?
            <Space size={12} direction='vertical'>
              <Space size={12} direction='vertical'>
                <Space direction='horizontal' style={{ display: "flex", justifyContent: "space-between", marginTop: "2rem" }}>
                  <Skeleton
                    active
                    title={{ width: 300, height: 50 }}
                    paragraph={{ rows: 0 }}
                  />
                  <Skeleton
                    active
                    title={{ width: 300, height: 50 }}
                    paragraph={{ rows: 0 }}
                  />
                </Space>
                <Skeleton.Node
                  style={{ width: 850, height: 450 }}
                  active
                >
                  <BarChartOutlined />
                </Skeleton.Node>
              </Space>
              <Skeleton.Node
                style={{ width: 700, height: 380 }}
                active
              >
                <BarChartOutlined />
              </Skeleton.Node>
              <Space direction='horizontal' style={{ display: "flex", justifyContent: "space-evenly", marginTop: ".5rem" }}>
                <Skeleton
                  active
                  title={{ width: 200, height: 50 }}
                  paragraph={{ rows: 0 }}
                />
                <Skeleton
                  active
                  title={{ width: 200, height: 50 }}
                  paragraph={{ rows: 0 }}
                />
              </Space>
            </Space>
            :
            header[0]?.score ?
              <>
                <div className='filter-section'>
                  <div className='filter-section-left'>
                    <img src={Filter} alt="" className="img_style2" />
                    <Label className='filter_text'>Filter</Label>
                    <Button className={`btn_filter1${(btn === "joie") ? '_selected1' : ''}`} onClick={() => this.filter('joie')}>Joie</Button>
                    <Button className={`btn_filter2${(btn === "surprise") ? '_selected2' : ''}`} onClick={() => this.filter('surprise')}>Surprise</Button>
                    <Button className={`btn_filter3${(btn === "tristesse") ? '_selected3' : ''}`} onClick={() => this.filter('tristesse')}>Tristesse</Button>
                    <Button className={`btn_filter4${(btn === "colère") ? '_selected4' : ''}`} onClick={() => this.filter('colère')}>Colère</Button>
                  </div>
                  <div>
                    <span className='journey_map' onClick={() => this.filter('map')}>Courbe d’émotions</span>

                  </div>
                </div>
                <div className='chart_container_analyse'>
                  <div className='chart-section'>
                    <div className='negativePositeveContainer'>
                      <div className='negativeBar'> <span className='experienceText' > Expérience negative </span> </div>
                      <div className='positiveBar'> <span className='experienceText'> Expérience positive </span> </div>
                    </div>
                    <div className='chart_style' data-tip='Moyenne des ressentis par étape'>
                      {btn === 'joie' && <Bar
                        data={data}
                        options={options2}
                      />}
                      {btn === 'surprise' && <Bar
                        data={data1}
                        options={options2}
                      />}
                      {btn === 'tristesse' && <Bar
                        data={data2}
                        options={options2}
                      />}
                      {btn === 'colère' && <Bar
                        data={data3}
                        options={options2}
                      />}
                    </div>
                  </div>
                </div>
                {/* <Row style={{ justifyContent: 'center', }}>
                            <Col>
                                <Row>
                                    <Label className='label_top'>Top 3</Label>
                                    <div className='top_1'></div>
                                    <div className='top_2'></div>
                                    <div className='top_3'></div>
                                </Row>
                            </Col>
                            <Col>
                                <Row>

                                    <Label className='label_top2'>Top 3</Label>
                                    <div className='top_3_1'></div>
                                    <div className='top_3_2'></div>
                                    <div className='top_3_3'></div>
                                </Row>

                            </Col>
                        </Row> */}


                <Row className='body2'>
                  {/* <Row className='row_1'>
                    <img src={Info} alt="" className="img_style" />
                    <img src={Excel} alt="" className="img_style" />
                  </Row> */}
                  <div className='chart_container'>
                    <Row className='filter'>

                      <Input type="select" name="select" className='btn_filter_select_scena' id="exampleSelect" onChange={this.onChange}>
                        {this.state.scenario?.steps?.map((step, idx) => {
                          return <option key={idx} value={step} selected>Etape {idx + 1}</option>
                        })
                        }

                      </Input>
                      <span className='duration '></span>
                      <span className='journey_map'>Emotion par étape </span>
                    </Row>
                    <Row className='filter'>
                      {/* {this.renderModalCommentaire()} */}
                    </Row>
                    <Row className='filter'>
                      <span></span>
                      <span></span>
                    </Row>
                    <Row style={{ width: '67%', margin: 'auto' }} >
                      <Bar data={dataOptions} options={options} />
                    </Row>
                  </div>
                </Row>

              </>
              :
              <span className='mt-5'>
                Veuillez selectionner un scénario
              </span>
        }
      </div>
    </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  scenario: state.scenario,
});

export default connect(
  mapStateToProps,
  {}
)(FacialRecognition);