import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());


export const getPanelStats = async (scenario_id, filter) => {
    const stats = await axios.get(apiEndpoint + `/api/analyze/panel/testers`, {
        headers: getAuthorization(),
        params: { scenario_id: scenario_id, filter: filter }
    })
    const { data } = stats
    return data;
}

export const getScenarioAnalysesR = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/analyze/scenario`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = scenario
    return data;
}
export const getScenarioAnalysesRC = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/analyze/comments/scenario`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = scenario
    return data;
}

export const getStepAnalysesR = async (id) => { 
    const step = await axios.get(apiEndpoint + `/api/analyze/step`, { 
        headers: getAuthorization(), 
        params: { step_id: id }
    }) 
    const { data } = step 
    return data;  
} 
export const getStepAnalysesRC = async (id) => { 
    const step = await axios.get(apiEndpoint + `/api/analyze/comments/step`, { 
        headers: getAuthorization(), 
        params: { step_id: id }
    }) 
    const { data } = step 
    return data;  
} 

export const getStepAnalyseVideoDownload = async (id) => {
    const stepAnalyseVideo = await axios.get(apiEndpoint + `/api/scenario/step/video/download`, {
        params: { 
            answer_id : id 
        },
        headers: getAuthorization(),
        responseType: 'blob',
    });
    const { data } = stepAnalyseVideo
    return data;
}

export const getStepAnalyseVideoStream = async (answer_id) => {
    
    const url = new URL(apiEndpoint + `/api/scenario/step/video/stream`);
    url.searchParams.append('answer_id', answer_id);

    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 200000); 

    try {
            const response = await fetch(url.toString(), {
            headers: {
                ...getAuthorization(), 
            },
            signal: controller.signal
        });

        if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.startsWith('video/')) {
        const videoStreamBlob = await response.blob();
        return videoStreamBlob;
    } else {
        const message = await response.json();
        return message;
    }

    } catch (error) {
    if (error.name === 'AbortError') {
        console.error('Request timed out');
    } else {
        console.error('Fetch error:', error);
    }
    throw error;
    } finally {
        clearTimeout(timeoutId);
    }
}

export const getTesterAnalyses = async (id) => {
    const tester = await axios.get(apiEndpoint + `/api/analyze/test/${id}`, {
        headers: getAuthorization()
    })
    const { data } = tester
    return data;
}
export const getTester = async (id) => {
    const tester = await axios.get(apiEndpoint + `/api/scenario/testers/details`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = tester
    return data;
}
export const getjourneyMapTester = async (id) => {
    const journeyMap = await axios.get(apiEndpoint + `/api/analyze/average/tests/scenario`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = journeyMap
    return data;
}
export const getTestId = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/scenario/${id}`, {
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}
export const getTestById = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/${id}`, {
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}
export const getAnalysesByStep = async (id) => {
    const analyse = await axios.get(apiEndpoint + `/api/google-analyze/step/${id}`, {
        headers: getAuthorization()
    })
    const { data } = analyse
    return data;
}

export const getAnalysesByTester = async (idTester, scenario_id) => {
    const analyse = await axios.get(apiEndpoint + `/api/google-analyze/tester/scenario`, {
        headers: getAuthorization(),
        params: { scenario_id: scenario_id, tester_id : idTester }

    })
    const { data } = analyse
    return data;
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}
function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}